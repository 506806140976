import React, {Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.sass';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Main from './routes/main';
import subaruWallpaper from './images/wp8958508-subaru-impreza-22b-wallpapers_crop.jpg'; // from https://wallpapercave.com/subaru-impreza-22b-wallpapers

const Prices = React.lazy(() => import("./routes/prices"));
const Gallery = React.lazy(() => import("./routes/gallery"));
const Contacts = React.lazy(() => import("./routes/contacts"));

function App() {
    return (
        <>
            <div className='wallpaper' style={{backgroundImage: `url(${subaruWallpaper})`}}/>

            <Header/>
            <Navigation/>

            <Routes>
                <Route path='*' element={<Navigate replace to='/'/>}/>
                <Route
                    path="/"
                    element={<Main/>}
                />
                <Route
                    path="prices"
                    element={
                        <Suspense fallback={<>...</>}>
                            <Prices/>
                        </Suspense>
                    }
                />
                <Route
                    path="gallery"
                    element={
                        <Suspense fallback={<>...</>}>
                            <Gallery/>
                        </Suspense>
                    }
                />
                <Route
                    path="contacts"
                    element={
                        <Suspense fallback={<>...</>}>
                            <Contacts/>
                        </Suspense>
                    }
                />

            </Routes>

            <div className='dummy-vertical-spacer'/>

            <Footer sticky={false}/>
        </>
    );
}

export default App;

