import React from 'react';
import PropTypes from 'prop-types';

export default function Footer({sticky}) {
    return (
        <div className={`footer${sticky ? '-sticky' : ''}`}>
            <div className='footer-address'>
                "Сервис-555", г.Екатеринбург, ул. Онуфриева 55, ГСК «Ленинский»,
                3-я очередь, цокольный этаж, бокс 69.
            </div>
            <div className='footer-phones'>
                <div>+7-(903)-08-26-555 Алексей</div>
                <div>+7-(905)-80-32-654 Андрей</div>
            </div>
        </div>
    );
};

Footer.props = {
    sticky: PropTypes.bool.isRequired
};