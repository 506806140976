import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

export default function Navigation() {
    const items = [
        {path: '/', label: 'О нас'},
        {path: '/prices', label: 'Цены'},
        // {path: '/gallery', label: 'Фото'},
        {path: '/contacts', label: 'Контакты'},
    ].map(it => {
        return <Nav key={it.path}>
            <LinkContainer to={it.path}>
                <Nav.Item>
                    <Nav.Link href={it.path}>{it.label}</Nav.Link>
                </Nav.Item>
            </LinkContainer>
        </Nav>
    });
    return (
        <Navbar expand="lg" sticky='top'>
            <Container>
                {items}
            </Container>
        </Navbar>
    );
};