import React from 'react';
import s555logo from '../images/header_test.jpg';
import s555 from '../images/555_new_plain.svg';

export default function Header() {
    return (
        <div className='header'>
            <div className='header-logo' style={{backgroundImage: `url(${s555logo})`}}/>
            <img src={s555} className='header-logo-555' title='Service555' alt='Service555'/>
        </div>
    );
};