import React from 'react';

export default function Main() {
    return (
        <div className='main-body'>
            <div className='main-header'>
                Здравствуйте, дорогие владельцы автомобилей SUBARU !
            </div>

            <div className='body-paragraph'>
                В нашем городе есть немало автосервисов, якобы занимающихся ремонтом и обслуживанием автомобилей SUBARU,
                но прежде чем подходить к этим автомобилям, нужно поистине любить и уважать эту марку.
            </div>

            <div className='body-paragraph'>
                Сервис "555" предлагает Вам квалифицированный ремонт и обслуживание автомобилей SUBARU. Девиз нашего
                сервиса - правильное и качественное обслуживание и ремонт автомобилей SUBARU !
            </div>

            <div className='body-paragraph'>
                Мы все очень любим эту марку за отличающийся от других мировых производителей дизайн, за уникальность и
                простоту конструкции. Но основным отличием является надежность и безопасность, которую мы ощущаем при
                управлении этим выдающимся автомобилем.
            </div>

            <div className='body-paragraph'>
                Наш многолетний опыт работы позволяет быстро и правильно определять и устранять все неполадки,
                возникающие при эксплуатации в наших тяжелых климатических условиях. В первую очередь мы всегда
                прислушиваемся к Вашим мнениям и пожеланиям.
            </div>

            <div className='body-paragraph'>
                К Вашим услугам автосервис "555" предлагает: диагностику, ремонт и обслуживание
                двигателей, подвески и механических КПП автомобилей SUBARU. Также будем рады видеть в нашем сервисе
                автомобили для проведения ТО после окончания гарантии. В нашем сервисе всегда можно видеть все работы,
                которые проводятся с Вашим автомобилем.
            </div>

            <div className='body-paragraph'>
                В работе мы используем как оригинальные запчасти, так и качественные дубликаты. Все основные расходные
                материалы у нас имеются в наличии, есть возможность заказа как новых, так и контрактных запчастей.
            </div>

            <div className='body-paragraph'>
                Приезжайте, и все увидите сами, ведь мы поистине можем себя
                называть фанатами этой марки!
            </div>

            {/*
            <Row>
                <Col/>
                <Col>Мы находимся по адресу:</Col>
                <Col>улица Онуфриева 55, ГСК "Ленинский" (въезд за АЦ "Краснолесье"), третья очередь, цокольный этаж, бокс 69. (схема проезда)


                    Мы работаем с 10:00 до 19:00.

                    Выходные: ЧЕТВЕРГ и ВОСКРЕСЕНИЕ.</Col>

                <Col/>
            </Row>
            <Row/>
            <Row>
                <Col/>
                <Col>Телефоны:</Col>
                <Col>
                    8-903-0826-555 Алексей
                    8-905-8032-654 Андрей
                    8-912-6066-400
                </Col>
                <Col/>
            </Row>
*/}

        </div>
    );
};